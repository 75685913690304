import React from 'react'
import FormQuestion from './FormQuestion'
import 'typeface-montserrat';


export default function QuizPage() {
  return (
    <>
    <FormQuestion/>
    </>
  )
}
