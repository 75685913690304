import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import axiosClient from "../config/axiosClient";
import { CheckCircleOutline } from "@mui/icons-material";

export default function FQConfirmSubmitDialog({
  openConfirmDialog,
  setOpenConfirmDialog,
  submissionId,
}) {
  const [isSuccess, setIsSuccess] = useState(false);
  const handleConfirm = async () => {
    try {
      const res = await axiosClient({
        baseURL: `${process.env.REACT_APP_API_URL}/api/quizzes/submit`,
        method: "POST",
        data: {
          submission: submissionId,
        },
      });
      if (res) {
        setIsSuccess(!isSuccess);
      }
      // console.log(res);
    } catch (err) {
      console.log("Could not submit", err);
    }
  };
  return (
    <Dialog
      fullWidth="sm"
      open={openConfirmDialog}
      onClose={() => setOpenConfirmDialog(!openConfirmDialog)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ borderRadius: "10px !important" }}
    >
      {!isSuccess && (
        <DialogTitle
          sx={{
            fontSize: "32px",
            color: "var(--yellow)",
            fontWeight: "bold",
            width: "80%",
            m: "0 auto",
            padding: "24px 24px 0",
            textAlign: "center",
          }}
        >
          XÁC NHẬN NỘP BÀI
        </DialogTitle>
      )}

      <DialogContent sx={{ p: "0 !important", textAlign: "center" }}>
        {!isSuccess ? (
          <Box sx={{ width: "80%", m: "0 auto" }}>
            <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
              Lưu ý khi đã Nộp bài thành công bạn không được kiểm tra và thay
              đổi các đáp án đã nộp.
            </Typography>
          </Box>
        ) : (
          <Box textAlign="center" p="48px">
            <CheckCircleOutline
              sx={{ fontSize: "76px", mb: "18px", color: "var(--yellow)" }}
            />
            <Typography fontSize="24px" fontWeight="bold" color="var(--black)">
              Nộp bài thành công!
            </Typography>
          </Box>
        )}
      </DialogContent>
      {!isSuccess && (
        <DialogActions
          sx={{
            padding: "35px 0 42px",
            width: "82%",
            m: "0 auto",
            flexDirection: "column",
            justifyContent: "center",
            gap: "24px",
          }}
        >
          <Button
            fullWidth
            onClick={handleConfirm}
            sx={{
              m: "0 !important",
              fontSize: "20px",
              fontWeight: "bold",
              background: "var(--yellow)",
              color: "#fff",
              "&:hover": {
                background: "var(--yellow)",
              },
            }}
            autoFocus
          >
            Xác nhận
          </Button>
          <Button
            fullWidth
            onClick={() => setOpenConfirmDialog(false)}
            sx={{
              m: "0 !important",
              fontSize: "20px",
              fontWeight: "bold",
              background: "var(--black)",
              color: "#fff",
              "&:hover": {
                background: "var(--black)",
              },
            }}
            autoFocus
          >
            Hủy
          </Button>
          
        </DialogActions>
      )}
    </Dialog>
  );
}
