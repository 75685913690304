import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import axiosClient from "../config/axiosClient";

export default function FQLoginDialog({ setList, trigger }) {
  const [open, setOpen] = useState(true);
  const [error, setError] = useState();
  const [login, setLogin] = useState({
    code: undefined,
    name: undefined,
  });

  const handleLogin = async () => {
    if (!!login?.code && !!login?.name) {
      try {
        const res = await axiosClient({
          method: "POST",
          baseURL: `${process.env.REACT_APP_API_URL}/api/quizzes/take`,
          data: login,
        });
        if(res?.data){
            setOpen(false)
            setList(res?.data)
        }
      } catch (err) {
        console.log("Login failed", err);
      }
    } else {
      setError("Vui lòng nhập đủ SBD và họ tên");
    }
  };

  return (
    <Dialog
      fullWidth="sm"
      open={open}
      //   onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ borderRadius: "10px !important" }}
    >
      <DialogTitle
        sx={{
          fontSize: "32px",
          color: "var(--yellow)",
          fontWeight: "bold",
          width: "80%",
          m: "0 auto",
          padding: "24px",
          textAlign: "center"
        }}
      >
        THÔNG TIN THÍ SINH
      </DialogTitle>
      <DialogContent sx={{ p: "0 !important" }}>
        <Box sx={{ width: "80%", m: "0 auto" }}>
          <Box mb="24px">
            <Box sx={{ display: "flex", gap: "6px", mb: "8px" }}>
              <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
                Số báo danh
              </Typography>
              <Typography color="#FF0000">*</Typography>
            </Box>
            <TextField
              onChange={(e) => setLogin({ ...login, code: e.target.value })}
              required
              size="small"
              placeholder="Số báo danh"
              variant="filled"
              fullWidth
            />
          </Box>

          {/* -------- */}
          <Box>
            <Box sx={{ display: "flex", gap: "6px", mb: "8px" }}>
              <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
                Họ tên
              </Typography>
              <Typography color="#FF0000">*</Typography>
            </Box>
            <TextField
              onChange={(e) => setLogin({ ...login, name: e.target.value })}
              required
              size="small"
              placeholder="Họ tên thí sinh"
              variant="filled"
              fullWidth
            />
          </Box>
          {error && (
            <Typography mt="12px" color="error" fontSize="12px">
              {error}
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: "35px 0 42px" }}>
        <Button
          onClick={handleLogin}
          sx={{
            fontSize: "20px",
            fontWeight: "bold",
            width: "82%",
            m: "0 auto",
            background: "var(--yellow)",
            color: "#fff",
            "&:hover": {
              background: "var(--yellow)",
            },
          }}
          autoFocus
        >
          BẮT ĐẦU
        </Button>
      </DialogActions>
    </Dialog>
  );
}
