import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React, { useEffect, useState } from "react";
import axiosClient from "../config/axiosClient";

export default function FormQuestionItem({
  item,
  submissionId,
  setTrigger,
  setPickedItems,
  pickedItems,
  disabled,
}) {
  const [value, setValue] = useState(null);
  const handleSend = async (id, answer) => {
    try {
      const res = await axiosClient({
        baseURL: `${process.env.REACT_APP_API_URL}/api/quizzes/save-answer`,
        method: "PUT",
        data: {
          submission: submissionId,
          question: answer,
          answer: id,
        },
      });
      setTrigger((prev) => !prev);
    } catch (err) {
      console.log("Send failed!", err);
    }
  };

  useEffect(() => {
    if (item?.answers) {
      const result = item?.answers?.find((v) => v?.isSelected === true);
      if (result) {
        setValue(result?._id);
      }
    }
  }, [item]);

  return (
    <RadioGroup
      aria-labelledby="demo-row-radio-buttons-group-label"
      name="radio-buttons-group"
      value={value}
      onChange={(event) => {
        const selectedValue = event.target.value;
        const selectedAnswer = item?.answers?.find(
          (val) => val._id === selectedValue
        );
        handleSend(selectedAnswer?._id, selectedAnswer?.question);
        setValue(selectedValue); // This updates the selected value
        const availableItem = pickedItems?.find((v) => v?._id === item?._id);
        if (!availableItem) {
          setPickedItems([...pickedItems, item]);
        }
      }}
    >
      {item?.answers?.map((val) => {
        return (
          <FormControlLabel
            sx={{
              color: (!!value
                ? val?._id === value
                : val?.isSelected)
                ? "var(--yellow)"
                : "#fff",
              fontSize: "18px",
              pl: "16px",
              mb: "16px",
              pointerEvents: disabled ? "none" : "normal",
              ".MuiButtonBase-root span svg path": {
                color: "var(--yellow)",
              },
            }}
            key={val?._id}
            value={val?._id}
            control={<Radio />}
            label={val?.content}
          />
        );
      })}
    </RadioGroup>
  );
}
