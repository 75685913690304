import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import QuizPage from './quiz';
import OverviewPage from './overview';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<QuizPage />} />
        <Route path="/overview" element={<OverviewPage />} />
      </Routes>
    </Router>
  );
}

export default App;
