import {
  Box,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import axiosClient from "../config/axiosClient";
import { useLocation } from "react-router-dom";

export default function OverviewPage() {
  const [summary, setSummary] = useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const key = queryParams.get("key");
  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await axiosClient({
          baseURL: `${process.env.REACT_APP_API_URL}/api/quizzes/summary?key=${key}`,
        });
        if (res) {
          setSummary(res?.data);
        }
        // console.log(res?.data);
      } catch (err) {
        console.log("Could not get result", err);
      }
    };
    !!key && fetch();
  }, []);
  return (
    <Container maxWidth="false">
      <Box sx={{ maxWidth: "350px", width: "95%", m: "0 auto", p: "3% 0 2%" }}>
        <img style={{ width: "100%" }} src="/img/logo.png" alt="" />
      </Box>
      <Typography
        sx={{
          fontSize: "44px",
          textAlign: "center",
          width: "90%",
          fontWeight: "bold",
          m: "0 auto",
          p: "4% 0 2%",
        }}
      >
        Kết quả kiểm tra kiến thức tổng hợp <br /> Miss Universe Vietnam 2024
      </Typography>
      <Box sx={{ maxWidth: "232px", width: "80%", m: "0 auto", p: "0 0 2%" }}>
        <img style={{ width: "100%" }} src="/img/start.png" alt="" />
      </Box>
      <TableContainer
        sx={{
          maxWidth: "1600px",
          width: "95%",
          m: "0 auto 16%",
          borderRadius: "30px",
          borderBottomRightRadius: 0,
          borderBottomLeftRadius: 0,
          border: "1px solid var(--yellow)",
        }}
      >
        <Table sx={{ minWidth: 1200 }} aria-label="simple table">
          <TableHead
            sx={{ background: "var(--yellow)", color: "var(--black)" }}
          >
            <TableRow sx={{ bỏder: 0 }}>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  borderRight: "2px solid var(--black)",
                  fontSize: "20px",
                  borderBottom: 0,
                }}
                align="center"
              >
                STT
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  borderRight: "2px solid var(--black)",
                  fontSize: "20px",
                  borderBottom: 0,
                }}
                align="center"
              >
                SBD
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  borderRight: "2px solid var(--black)",
                  fontSize: "20px",
                  borderBottom: 0,
                }}
                align="center"
              >
                Họ tên thí sinh
              </TableCell>
              {summary?.header?.map((v) => {
                return (
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      borderRight: "1px solid var(--black)",
                      fontSize: "20px",
                      borderBottom: 0,
                    }}
                    align="center"
                    key={v?._id}
                  >
                    {v?.title ?? "-"}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {summary?.data?.map((v, i) => {
              return (
                <TableRow
                  key={v?._id}
                  sx={{
                    border: "1px solid var(--yellow)",
                    color: "white",
                    background: i % 2 !== 0 ? "#d5a97c33" : "var(--black)",
                  }}
                >
                  <TableCell
                    sx={{
                      color: "white",
                      fontWeight: "bold",
                      border: 0,
                      borderRight: "2px solid var(--yellow)",
                    }}
                    align="center"
                  >
                    {i + 1}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "white",
                      fontWeight: "bold",
                      border: 0,
                      borderRight: "2px solid var(--yellow)",
                    }}
                    align="center"
                  >
                    {v?.code ?? "-"}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "180px",
                      color: "white",
                      fontWeight: "bold",
                      border: 0,
                      borderRight: "2px solid var(--yellow)",
                    }}
                    align="center"
                  >
                    {v?.name ?? "-"}
                  </TableCell>
                  {v?.summary?.map((v, i) => {
                    return (
                      <React.Fragment key={i}>
                        <TableCell
                          align="center"
                          sx={{
                            border: 0,
                            borderRight: "2px solid var(--yellow)",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "white",
                              fontWeight: "bold",
                              fontSize: "20px",
                            }}
                          >
                            {v?.correct ?? 0}
                          </Typography>
                          <Typography
                            sx={{
                              color: "var(--yellow)",
                              fontWeight: "bold",
                              fontSize: "16px",
                            }}
                          >
                            ({v?.percent}%)
                          </Typography>
                        </TableCell>
                      </React.Fragment>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
