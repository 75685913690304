import {
  Box,
  Button,
  Container,
  Drawer,
  FormControl,
  FormLabel,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  MenuList,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import FormQuestionItem from "./FormQuestionItem";
import FQLoginDialog from "./FQLoginDialog";
import FQConfirmSubmitDialog from "./FQConfirmSubmitDialog";
import { Menu } from "@mui/icons-material";

export default function FormQuestion() {
  const refDirect = useRef(null);
  const [open, setOpen] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [list, setList] = useState([]);
  const [pickedItems, setPickedItems] = useState([]);
  const [pickedChildItem, setPickedChildItems] = useState([]);
  const [trigger, setTrigger] = useState(false);
  const [redirectPath, setRedirectPath] = useState(null);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <List>
        {list?.submission?.questions.map((v, index) => {
          const picked = pickedItems?.map((v) => v?._id);
          return (
            <ListItem key={v?._id} disablePadding>
              <ListItemButton onClick={() => handleDirectToQues(v?._id)}>
                <Typography
                  sx={{
                    color: picked?.includes(v?._id) ? "var(--yellow)" : "#fff",
                    fontWeight: picked?.includes(v?._id) ? "bold" : "",
                  }}
                >
                  Câu hỏi {index + 1}
                </Typography>
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );

  const handleDirectToQues = (id) => {
    setRedirectPath(id);
  };

  useEffect(() => {
    if (!!redirectPath) {
      refDirect.current?.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [redirectPath]);

  useEffect(() => {
    if (list?.submission?.questions) {
      const item = list?.submission?.questions?.filter(
        (v) => v?.marked === true
      );
      setPickedItems(item);
    }
  }, [list]);

  return (
    <Container maxWidth="false">
      <Grid container spacing={4}>
        <Grid
          item
          xs={list?.submission?.questions?.length > 0 ? 2 : 0}
          sx={{
            borderRight:
              list?.submission?.questions?.length > 0 &&
              "1px solid var(--yellow)",
            "@media (max-width: 900px)": {
              display: "none",
            },
          }}
        >
          <Box
            sx={{
              maxHeight: "75vh",
              height: "100%",
              overflow: "auto",
              width: "100%",
              pt: "24px",
              position: "sticky",
              top: 0,
            }}
          >
            <MenuList>
              {list?.submission?.questions?.map((v, i) => {
                const picked = pickedItems?.map((v) => v?._id);

                return (
                  <MenuItem key={v?._id}>
                    <ListItemButton onClick={() => handleDirectToQues(v?._id)}>
                      <ListItemText>
                        <Typography
                          sx={{
                            color: picked?.includes(v?._id)
                              ? "var(--yellow)"
                              : "#fff",
                            fontWeight: picked?.includes(v?._id) ? "bold" : "",
                          }}
                        >
                          Câu hỏi {i + 1}
                        </Typography>
                      </ListItemText>
                    </ListItemButton>
                  </MenuItem>
                );
              })}
            </MenuList>
          </Box>
          {list && list?.submission?.questions?.length > 0 && (
            <>
              <Box
                sx={{
                  position: "fixed",
                  padding: "6px 36px 6px 36px",
                  borderRadius: "100px",
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  bottom: "15%",
                  left: "0",
                  background: "var(--yellow)",
                }}
              >
                <Typography color="var(--black)" fontSize="16px">
                  Đã hoàn thành
                </Typography>
                <Typography
                  color="var(--black)"
                  fontSize="32px"
                  fontWeight="bold"
                >
                  {pickedItems?.length ?? 0}/{list?.total ?? 0}
                </Typography>
              </Box>
              <Button
                disabled={list?.submission?.status === "submitted"}
                onClick={() => setOpenConfirmDialog(!openConfirmDialog)}
                sx={{
                  position: "fixed",
                  left: 0,
                  bottom: "7%",
                  padding: "6px 36px 6px 36px",
                  borderTopRightRadius: "100px !important",
                  borderBottomRightRadius: "100px !important",
                  fontSize: "24px",
                  background:
                    list?.submission?.status === "submitted"
                      ? "#767676"
                      : "var(--yellow)",
                  borderRadius: "0",
                  color: "var(--black)",
                  fontWeight: "bold",
                  transition: "all 0.2s",
                  "&:hover": {
                    opacity: 0.8,
                    background: "var(--yellow)",
                  },
                }}
              >
                NỘP BÀI
              </Button>
            </>
          )}
        </Grid>
        <Grid
          item
          md={list?.submission?.questions?.length > 0 ? 10 : 12}
          xs={12}
        >
          {/* SIDEBAR BTN */}
          {list?.submission?.questions?.length > 0 && (
            <>
              <Button
                onClick={toggleDrawer(true)}
                sx={{
                  display: "none",
                  background: "var(--yellow)",
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  position: "fixed",
                  zIndex: 2,
                  top: "2%",
                  left: 0,
                  "@media (max-width: 900px)": { display: "flex", width: "50px", minWidth: "auto" },
                  "&:hover": {
                    background: "var(--yellow)",
                  },
                }}
              >
                <Menu sx={{ color: "var(--black)", fontSize: "36px" }} />
              </Button>
              <Drawer
                sx={{
                  ".MuiPaper-root": {
                    background: "var(--black)",
                  },
                }}
                open={open}
                onClose={toggleDrawer(false)}
              >
                {DrawerList}
              </Drawer>
            </>
          )}

          <Box
            sx={{ maxWidth: "350px", width: "80%", m: "0 auto", p: "3% 0 2%" }}
          >
            <img style={{ width: "100%" }} src="/img/logo.png" alt="" />
          </Box>
          <Typography
            sx={{
              fontSize: "44px",
              textAlign: "center",
              width: "90%",
              fontWeight: "bold",
              m: "0 auto",
              p: "4% 0 2%",
              "@media (max-width: 991px)": {
                fontSize: "36px",
                pt: "4%"
              }
            }}
          >
            Bài kiểm tra kiến thức tổng hợp <br /> Miss Universe Vietnam 2024
          </Typography>
          <TableContainer
            sx={{
              maxWidth: "480px",
              width: "90%",
              m: "0 auto",
              border: "1px solid var(--yellow)",
            }}
          >
            <Table>
              <TableHead>
                <TableRow sx={{ background: "var(--yellow)" }}>
                  <TableCell
                    colSpan={2}
                    sx={{ padding: "2px 12px", fontWeight: "bold", border: 0 }}
                  >
                    <Typography
                      sx={{ color: "var(--black)", fontSize: "24px", fontWeight: "bold" }}
                    >
                      Thông tin Thí sinh
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow sx={{ borderBottom: "1px solid var(--yellow)" }}>
                  <TableCell sx={{ padding: "6px 12px", border: 0 }}>
                    <Typography sx={{ color: "#fff", fontSize: "20px" }}>
                      Số báo danh:
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ padding: "6px 12px", border: 0 }}>
                    <Typography
                      sx={{
                        color: "var(--yellow)",
                        fontSize: "24px",
                        fontWeight: "bold",
                      }}
                    >
                      {list?.submission?.code ?? "-"}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow sx={{ borderBottom: "1px solid var(--yellow)" }}>
                  <TableCell sx={{ padding: "6px 12px", border: 0 }}>
                    <Typography sx={{ color: "#fff", fontSize: "20px" }}>
                      Họ tên Thí sinh:
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ padding: "6px 12px", border: 0 }}>
                    <Typography
                      sx={{
                        color: "var(--yellow)",
                        fontSize: "24px",
                        fontWeight: "bold",
                      }}
                    >
                      {list?.submission?.name ?? "-"}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ maxWidth: "232px", width: "80%", m: "0 auto", p: "2% 0" }}>
            <img style={{ width: "100%" }} src="/img/start.png" alt="" />
          </Box>
          {/* QUESTIONS */}
          {!!list?.submission?.questions?.length && (
            <>
              {list?.submission?.questions?.map((v, i) => {
                const submissionId = list?.submission?._id;
                return (
                  <Box
                    ref={redirectPath === v?._id ? refDirect : null}
                    key={v?._id}
                    sx={{ width: "100%" }}
                    textAlign="left"
                    mb="68px"
                    borderRadius="30px"
                    border="1px solid var(--yellow)"
                  >
                    {/* TITLE */}
                    <FormControl sx={{ width: "100%" }}>
                      <Box
                        sx={{
                          paddingLeft: "6%",
                          fontWeight: "bold",
                          padding: "12px 0 12px 6%",
                          fontSize: "32px",
                          color: "var(--black)",
                          background: "var(--yellow)",
                          borderRadius: "30px",
                          borderBottomRightRadius: "0",
                          borderBottomLeftRadius: "0",
                        }}
                      >
                        Câu hỏi {i + 1}:
                      </Box>
                      <Box sx={{ padding: "2% 6%" }}>
                        {/* <FormLabel
                          sx={{
                            fontWeight: "bold",
                            color: "#fff !important",
                            fontSize: "24px",
                            mb: "16px",
                            display: "block",
                            whiteSpace: "break-spaces",
                          }}
                          id="demo-radio-buttons-group-label"
                        >
                        </FormLabel> */}
                        <Typography sx={{
                            fontWeight: "bold",
                            color: "#fff !important",
                            fontSize: "24px",
                            mb: "16px",
                            display: "block",
                            whiteSpace: "break-spaces",
                          }} variant="body1" dangerouslySetInnerHTML={{ __html: v?.title }} />
                        <FormQuestionItem
                          disabled={list?.submission?.status === "submitted"}
                          pickedItems={pickedItems}
                          setPickedItems={setPickedItems}
                          setTrigger={setTrigger}
                          item={v}
                          submissionId={submissionId}
                        />
                      </Box>
                    </FormControl>
                  </Box>
                );
              })}
            </>
          )}
          <Box sx={{ textAlign: "center", mt: "36px", pb: "12%" }}>
            <Button
              disabled={list?.submission?.status === "submitted"}
              onClick={() => setOpenConfirmDialog(!openConfirmDialog)}
              sx={{
                fontSize: "32px",
                background:
                  list?.submission?.status === "submitted"
                    ? "#767676"
                    : "var(--yellow)",
                borderRadius: "100px",
                padding: "0.5% 12%",
                color: "var(--black)",
                fontWeight: "bold",
                transition: "all 0.2s",
                "&:hover": {
                  opacity: 0.8,
                  background: "var(--yellow)",
                },
              }}
            >
              NỘP BÀI
            </Button>
          </Box>
          {list?.submission?.questions?.length > 0 && (
           <>
            <Box
              sx={{
                position: "fixed",
                padding: "6px 0",
                borderRadius: "100px",
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                bottom: "2%",
                left: "0",
                background: "var(--yellow)",
                display: "none",
                "@media (max-width: 991px)": {
                  display: "block",
                  bottom: "13%",
                  width: "150px",
                },
              }}
            >
              <Typography color="var(--black)" fontSize="16px" sx={{ "@media (max-width: 991px)": {
                fontSize: "14px",
                pl: "16px"
              }}}>
                Đã hoàn thành
              </Typography>
              <Typography
                color="var(--black)"
                fontSize="32px"
                fontWeight="bold"
                sx={{fontSize: "24px", pl: "16px"}}
              >
               {pickedItems?.length ?? 0}/{list?.total ?? 0}
              </Typography>
            </Box>
            <Button
                disabled={list?.submission?.status === "submitted"}
                onClick={() => setOpenConfirmDialog(!openConfirmDialog)}
                sx={{
                  position: "fixed",
                  left: 0,
                  bottom: "7%",
                  padding: "6px 0",
                  width: "150px",
                  borderTopRightRadius: "100px !important",
                  borderBottomRightRadius: "100px !important",
                  fontSize: "20px",
                  background:
                    list?.submission?.status === "submitted"
                      ? "#767676"
                      : "var(--yellow)",
                  borderRadius: "0",
                  color: "var(--black)",
                  fontWeight: "bold",
                  transition: "all 0.2s",
                  justifyContent: "left",
                  pl: "16px",
                  "&:hover": {
                    opacity: 0.8,
                    background: "var(--yellow)",
                  },
                }}
              >
                NỘP BÀI
              </Button>
           </>
          )}
        </Grid>
      </Grid>
      <FQConfirmSubmitDialog
        submissionId={list?.submission?._id}
        openConfirmDialog={openConfirmDialog}
        setOpenConfirmDialog={setOpenConfirmDialog}
      />
      <FQLoginDialog setList={setList} trigger={trigger} />
    </Container>
  );
}
